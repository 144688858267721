.blue_area_links {
  padding: 100px 0 0 0;
  background-color: theme-color("primary");
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 61%;
    left: 0;
    width: 392px;
    height: 392px;
    background-image: url(../../../img/lijevo-logo-background.png);
    background-size: contain;
    background-position: left;
    opacity: 1;
    z-index: 0;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 20px;
  }

  h2 {
    margin: 0;
    font-size: 32px;
    font-family: "Lora", serif;
    color: white;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    display: block;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }

    li {
      display: inline-block;
      margin-bottom: 80px;

      @media (max-width: map-get($grid-breakpoints, md)) {
        margin-bottom: 20px;
      }
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      flex-direction: column;

      li {
        text-align: center;
        padding: 17px 0;
      }
    }

    a {
      color: white;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
    }

    img {
      margin-right: 24px;
      float: left;
      width: unset;
    }
  }
}