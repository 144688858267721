.page-frontpage {
    background-color: #f3f3f3;

    .portlet_news_show_headlines {
        h2 {
            margin: 0;
            font-size: 32px;
            font-family: "Lora", serif;
            color: white;
            margin-bottom: 40px;
            position: relative;
            z-index: 1;
        }

        &:before {
            background-color: theme-color("primary");
            content: '';
            width: 100%;
            height: 200px;
            position: absolute;
            left: 0;
            right: 0;
        }


        .news_show_headlines_render_articles {
            .card {
              border: 0 !important;
              background: transparent;
             

              &:hover {
                

                .card-body-content {
                  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
                  transform: translateY(-10px);
                }

                .pic img {
                  transform: translateY(-10px);
                }

              }

              &:active {
                animation: bounce 0.3s ease;
              }
            }
          
            .card-body {
              padding: 0;
            }
          
            .top {
              display: flex;
              flex-direction: column;
          
              .pic {
                width: auto;
                height: 230px;
                z-index: 1;
          
                @media (max-width: map-get($grid-breakpoints, lg)) {
                  height: 270px;
                }
          
                @media (max-width: map-get($grid-breakpoints, md)) {
                  width: 100% !important;
                  height: 270px;
                }
          
                img {
                  object-fit: cover;
                  width: 416px;
                  height: 230px !important;
                  @include border-radius(20px);
                  @include transition(transform 0.3s ease);


                  // follow pic height
          
                  @media (max-width: map-get($grid-breakpoints, lg)) {
                    height: 270px !important;
                  }
          
                  @media (max-width: map-get($grid-breakpoints, md)) {
                    width: 100% !important;
                    height: 270px !important
                  }
                }
              }
          
          
            }
          
            .card-body-content {
              @include transition(box-shadow 0.3s ease, transform 0.3s ease);
              padding: 22px 22px 1px 22px;
              position: relative;
              background-color: white;
              @include border-radius(20px);
              margin-bottom: 22px;

              &:before {
                content: '';
                width: 100%;
                height: 50px;
                top: -31px;
                background-color: #fff;
                position: absolute;
                right: 0;
                left: 0;
              }

              .title {

                h5 {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                            line-clamp: 2; 
                    -webkit-box-orient: vertical;
                    font-family: "Lora", serif;
                }
                a {
                  color: theme-color("secondary");
          
                  &:hover {
                    text-decoration: none;
                    color: theme-color("primary");
                  }
                }
              }
    
              .title {
                color: theme-color("secondary");
              }
            }
          
            .link {
              display: flex;
              flex-direction: row;
              margin: 20px 0;
              justify-content: space-between;
              align-items: center;
              width: 127px;
          
              .date {
                font-size: 8px;
              }
          
              a {
                color: theme-color("secondary");
                font-weight: bold;
                font-size: 10px;
          
                &:hover {
                  color: theme-color("primary");
                  text-decoration: none;
                }
              }
            }
          }
    }
}

@keyframes bounce {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}