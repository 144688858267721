.black_area_links {
  padding: 40px 0px;
  background-color: theme-color("secondary");

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 20px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: flex;
    margin-bottom: 0;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      flex-direction: column;

      li {
        text-align: center;
        padding: 17px 0;
      }
    }

    a {
      color: white;
      font-size: 16px;
      font-weight: 600;
    }

    img {
      margin-right: 24px;
    }
  }
}