.students_area {
  padding: 130px 0 0 0;
  background-image: url(../../../img/students.png);
  min-height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.8);

  h2 {
    font-size: 32px;
    font-weight: bold;
    font-family: "Lora", serif;
    color: white;
    margin-top: 0;

    &::before {
      content: "";
      display: inline-block;
      width: 44px;
      height: 64px;
      background-image: url(../../../icons/covjeculjak.png);
      vertical-align: middle;
      margin-right: 27px;
      margin-top: -10px;
    }
  }


  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    display: block;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }

    li {
      margin-bottom: 80px;

      img {
        display: none;
      }
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      flex-direction: column;

      li {
        text-align: center;
        padding: 17px 0;
      }
    }

    a {
      color: white;
      font-size: 24px;
      font-weight: 600;
    }

  }
}