.featured_area {
  padding: 75px 0 0 0;
  background-image: linear-gradient(90deg, #154577 0%, rgba(11, 35, 60, 0) 100%), url(../../../img/lucrezia.png);
  min-height: 350px;
  background-size: cover;
  background-repeat: no-repeat;

  span {
    color: white;
    font-weight: bold;
    margin-bottom: 6px;
    font-size: 14px;
  }

  h2 {
    max-width: 470px;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 32px;
    font-family: "Lora", serif;
    color: white;
    margin-top: 0;
  }

  .btn-dark {
    color: theme-color("primary");
    border-color: white;
    background-color: white;
    font-weight: bold;
    font-size: 14px;
    padding: 13px 50px;
    @include border-radius(23px);


    &:hover {
      background-color: theme-color("primary");
      border-color: theme-color("primary");
      color: white;
    }
  }

}