.orange_area {
  padding: 73px 0px;
  background-color: theme-color("primary");

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 40px 0px;
  }

  h3 {
    color: white;
    font-size: 30px;
    font-weight: 600;
    max-width: 222px;
  }

  &__images {
    margin-top: 24px;

    img {
      margin-right: 20px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &__menus {
    ul {
      li {
        margin-bottom: 34px;

        @media (max-width: map-get($grid-breakpoints, lg)) {
          margin-bottom: 40px;
        }

        &:last-child {
          margin-bottom: 0px;
        }

        a {
          color: white;
          font-size: 20px;
          font-weight: 600;
          padding: 0;

          i {
            img {
              display: none;
            }
            &:before {
              content: "";
              display: inline-block;
              width: 20px;
              height: 5px;
              background-color: white;
              vertical-align: middle;
              margin-right: 20px;
            }
          }

          &:hover {
            text-decoration: none;
            color: theme-color("secondary");
            
            i {
              &:before {
                background-color: theme-color("secondary");
              }
            }
          }
        }
      }
    }
  }

}