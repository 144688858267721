.usefull_links {
  padding: 67px 0px;
  background-color: rgb(96, 107, 116);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    background-image: url(../../../img/rastika.png);
    background-size: cover;
    background-position: top;
    opacity: .04;
    z-index: 0;
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 40px 0px;
  }

  h2 {
    font-size: 32px;
    font-weight: bold;
    font-family: "Lora", serif;
    color: white;
    margin: 0;
    margin-bottom: 32px;


    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-top: 13px;
    }
  }

  ul {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    display: block;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }

    li {
      max-width: 241px;
      -moz-page-break-inside: avoid;
      -webkit-column-break-inside: avoid;
      break-inside: avoid;
      -o-column-break-inside: avoid;
      -ms-column-break-inside: avoid;
      column-break-inside: avoid;
      page-break-inside: avoid;
      margin-bottom: 40px;
      img {
        margin-right: 15px;
        max-width: 13px;
        margin-bottom: 4px;
      }
      a {
        padding: 13px 0px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;

        &:hover {
          color: theme-color("primary");
        }
      }
    }
  }
}